import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import { t } from 'i18next';
import React from 'react';
import { tokens } from 'src/locales/tokens';

interface MemberTypeFieldProps {
  isPresident?: boolean | null;
  isFounding?: boolean | null;
}

export const MemberTypeField: React.FC<MemberTypeFieldProps> = ({ isPresident, isFounding }) => {
  const theme = useTheme();
  const label = isPresident
    ? t(tokens.filters.type.president)
    : isFounding
      ? t(tokens.filters.type.foundingMember)
      : t(tokens.filters.type.member);
  const chipColor = isPresident
    ? theme.palette.success.main
    : isFounding
      ? theme.palette.info.main
      : theme.palette.warning.main;

  return (
    <Chip
      sx={{
        backgroundColor: chipColor,
        borderRadius: 1,
        width: '100%',
        color: theme.palette.primary.contrastText,
      }}
      label={label}
    />
  );
};
