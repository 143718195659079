import { Box, Stack, Typography } from '@mui/material';
import { useEffect } from 'react';
import { animateValue } from 'src/utils/animateValue';
type Stat = {
  iconSrc: string;
  name: string;
  number: number;
};

export const CardStatsDisplay = ({
  stat,
  statRef,
}: {
  stat: Stat;
  statRef: React.RefObject<HTMLParagraphElement>;
}) => {
  const duration = 500;
  useEffect(() => {
    if (statRef.current) {
      const statElement = statRef.current;
      animateValue({ obj: statElement, start: 0, end: stat.number, duration: duration });
    }
  }, [stat.number, statRef]);
  return (
    <Stack>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'center'}
        padding={0}
        margin={0}
      >
        <Box
          alt={stat.name}
          component="img"
          src={stat.iconSrc}
          sx={{
            height: '50%',
            maxHeight: '60px',
            width: 'auto',
            display: 'flex',
            alignSelf: 'center',
            margin: '-10px 10px',
          }}
        />
        <Typography sx={{ fontSize: '12px' }}>{stat.name}</Typography>
      </Stack>
      <Typography
        ref={statRef}
        sx={{ fontSize: '24px', fontWeight: 'bold' }}
      >
        {stat.number ?? 0}
      </Typography>
    </Stack>
  );
};
