import { Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { indigo } from 'src/theme/colors';

import { CardStatsDisplay } from './CardStatsDisplay';
interface StatsBundleProps {
  title: string;
  subtitle?: string;
  stat1: Stat;
  stat2: Stat;
}

type Stat = {
  iconSrc: string;
  name: string;
  number: number;
};

export const CardStatsDouble = ({ title, subtitle, stat1, stat2 }: StatsBundleProps) => {
  const stat1Ref = useRef<HTMLParagraphElement>(null);
  const stat2Ref = useRef<HTMLParagraphElement>(null);

  return (
    <Stack
      sx={{
        backgroundColor: 'grey.100',
        borderRadius: 2,
        padding: 1,
        color: indigo.main,
        textAlign: 'center',
        width: 'auto',
      }}
    >
      <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{title ?? '...'}</Typography>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-around'}
        sx={{
          mt: 1,
        }}
      >
        <Stack sx={{ alignItems: 'center' }}>
          {subtitle && <Typography sx={{ fontSize: '12px' }}>{subtitle}</Typography>}
          <Stack
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <CardStatsDisplay
              stat={stat1}
              statRef={stat1Ref}
            />
            <CardStatsDisplay
              stat={stat2}
              statRef={stat2Ref}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
