enum ClubResourceEnum {
  CLUB_USER = 'club/users',
  HELPDESK_TOPIC = 'club/helpdesk/topics',
  HELPDESK_TICKET = 'club/helpdesk/tickets',
  CLUBS = 'club/clubs',
  MEMBERS = 'club/members',
  PRESIDENT = 'club/presidents',
  DEPARTMENT = 'club/departments',
  REGISTRATIONS = 'club/club_registrations',
  AGENCIES = 'agencies',
  SETTINGS = 'club/club_settings',
  CONTRACT = 'club/contracts',
  LEADS = 'club/leads',
  INVITATIONS = 'club/requests/invitations',
}

export default ClubResourceEnum;
