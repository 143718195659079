import { Box, Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { indigo } from 'src/theme/colors';
import { animateValue } from 'src/utils/animateValue';

import { CardStatsDisplay } from './CardStatsDisplay';

interface StatsBundleProps {
  title: string;
  subtitleSection1?: string;
  section1Stat1: Stat;
  section1Stat2: Stat;
  subtitleSection2?: string;
  section2Stat1: Stat;
  section2Stat2: Stat;
}

type Stat = {
  iconSrc: string;
  name: string;
  number: number;
};

export const CardStatsSplit = ({
  title,
  section1Stat1,
  section1Stat2,
  subtitleSection1,
  subtitleSection2,
  section2Stat1,
  section2Stat2,
}: StatsBundleProps) => {
  const section1Stat1Ref = useRef<HTMLParagraphElement>(null);
  const section1Stat2Ref = useRef<HTMLParagraphElement>(null);
  const section2Stat1Ref = useRef<HTMLParagraphElement>(null);
  const section2Stat2Ref = useRef<HTMLParagraphElement>(null);
  return (
    <Stack
      sx={{
        backgroundColor: 'grey.100',
        borderRadius: 2,
        padding: 1,
        color: indigo.main,
        textAlign: 'center',
        width: 'auto',
      }}
    >
      <Typography sx={{ fontWeight: 'bold', fontSize: '14px' }}>{title ?? '...'}</Typography>
      <Stack
        display={'flex'}
        flexDirection={'row'}
        justifyContent={'space-around'}
        sx={{
          mt: 1,
        }}
      >
        <Stack sx={{ alignItems: 'center' }}>
          {subtitleSection1 && (
            <Typography sx={{ fontSize: '12px' }}>{subtitleSection1}</Typography>
          )}
          <Stack
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <CardStatsDisplay
              stat={section1Stat1}
              statRef={section1Stat1Ref}
            />
            <CardStatsDisplay
              stat={section1Stat2}
              statRef={section1Stat2Ref}
            />
          </Stack>
        </Stack>
        <Box
          sx={{
            width: '1px',
            height: '100',
            border: `1px solid ${indigo.main}`,
          }}
        />
        <Stack sx={{ alignItems: 'center' }}>
          {subtitleSection2 && (
            <Typography sx={{ fontSize: '12px' }}>{subtitleSection2}</Typography>
          )}
          <Stack
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            width={'100%'}
          >
            <CardStatsDisplay
              stat={section2Stat1}
              statRef={section2Stat1Ref}
            />
            <CardStatsDisplay
              stat={section2Stat2}
              statRef={section2Stat2Ref}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
