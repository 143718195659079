import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { StatsBundleLeads } from 'src/common/components/StatsBundleLeads';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { tokens } from 'src/locales/tokens';

import { StatsBundleSkeleton } from '../../clubs/skeletons/StatsBundleSkeleton';

interface Data {
  lead_total: number;
  lead_total_amount: number;
  lead_sent: number;
  lead_received: number;
  lead_accepted: number;
  lead_refused: number;
  lead_transformed: number;
  lead_cancelled: number;
  lead_not_transformed: number;
  lead_expired: number;
}

export const LeadsStats = () => {
  const [data, setData] = useState<Data>({
    lead_total: 0,
    lead_total_amount: 0,
    lead_sent: 0,
    lead_received: 0,
    lead_accepted: 0,
    lead_refused: 0,
    lead_transformed: 0,
    lead_cancelled: 0,
    lead_not_transformed: 0,
    lead_expired: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stats = await dataProvider.getStats('club/stats', {
          code: [
            'lead_total',
            'lead_sent',
            'lead_received',
            'lead_accepted',
            'lead_refused',
            'lead_transformed',
            'lead_cancelled',
            'lead_not_transformed',
            'lead_expired',
            'lead_total_amount',
          ],
        });

        const statsMap = stats['hydra:member'].reduce(
          (acc, stat) => ({
            ...acc,
            [stat.code]: stat.value,
          }),
          {}
        );

        setData({
          lead_total: statsMap.lead_total || 0,
          lead_total_amount: statsMap.lead_total_amount || 0,
          lead_sent: statsMap.lead_sent || 0,
          lead_received: statsMap.lead_received || 0,
          lead_accepted: statsMap.lead_accepted || 0,
          lead_refused: statsMap.lead_refused || 0,
          lead_transformed: statsMap.lead_transformed || 0,
          lead_cancelled: statsMap.lead_cancelled || 0,
          lead_not_transformed: statsMap.lead_not_transformed || 0,
          lead_expired: statsMap.lead_expired || 0,
        });
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Une erreur est survenue'));
      } finally {
        setIsLoading(false);
      }
    };
    void fetchData();
  }, []);

  const stats = [
    {
      statIconSrc: '/assets/icons/email.png',
      statName: t(tokens.domains.clubs.detail.leadsStats.totalSend),
      statStatus: 'pending_sign',
      showSubStats: false,
    },
    {
      statIconSrc: '/assets/icons/validation.png',
      statName: t(tokens.domains.clubs.detail.leadsStats.totalAccepted),
      statStatus: 'user_accepted',
      showSubStats: true,
    },
    {
      statIconSrc: '/assets/icons/handshake.png',
      statName: t(tokens.domains.clubs.detail.leadsStats.transformLeads),
      statStatus: 'user_refused',
      showSubStats: true,
    },
    {
      statIconSrc: '/assets/icons/calculator.png',
      statName: t(tokens.domains.clubs.detail.leadsStats.totalCreated),
      statStatus: 'president_refused',
      showSubStats: false,
    },
  ];

  if (isLoading) {
    return <StatsBundleSkeleton />;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <StatsBundleLeads
      data={data ?? []}
      stats={stats}
    />
  );
};
