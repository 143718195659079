import { t } from 'i18next';
import { Navigate } from 'react-router-dom';
import { Header } from 'src/common/components/grid/Header';
import { ListLayout } from 'src/common/layouts/react-admin/ListLayout';
import { useRole } from 'src/domains/system/auth/hooks/useRole';
import { tokens } from 'src/locales/tokens';
import { UserRole } from 'src/types/enums/user-roles-enum';

import { InvitationListTable } from './components/InvitationListTable';

export const InvitationsList = () => {
  const isAdmin = useRole(UserRole.ADMIN);
  const isSuperAdmin = useRole(UserRole.SUPER_ADMIN);

  if (!isAdmin && !isSuperAdmin) {
    return (
      <Navigate
        to="/401"
        replace
      />
    );
  }
  return (
    <ListLayout seoTitle={t(tokens.seo.invitations.title)}>
      <Header
        title={t(tokens.domains.invitations.invitationsHeaderTitle)}
        resource="club/requests/invitations"
        hasExport={false}
      />
      <InvitationListTable />
    </ListLayout>
  );
};
