import { Box } from '@mui/system';
import React from 'react';
import { Pagination } from 'react-admin';
import { ListBaseLayout } from 'src/common/layouts/react-admin/ListBaseLayout';

import { ClubLeadsGrid } from '../../clubs/components/grid//ClubLeadsGrid';

import { LeadsFilters } from './LeadsFilters';
import { LeadsStats } from './LeadsStats';

export const LeadsListTable: React.FC = () => {
  return (
    <ListBaseLayout>
      <Box sx={{ marginBottom: 4, marginLeft: 1 }}>
        <LeadsStats />
      </Box>
      <LeadsFilters />
      <ClubLeadsGrid />
      <Pagination />
    </ListBaseLayout>
  );
};
