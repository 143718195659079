export enum FormatOptionValuesEnum {
  monthly = 'monthly',
  fortnightly = 'fortnightly',
  weekly = 'weekly',
}

export enum StatusOptionValuesEnum {
  REFUSED = 'refused',
  TRANSFORMED = 'transformed',
  NOT_TRANSFORMED = 'not_transformed',
  SENT = 'sent',
  CANCELLED = 'cancelled',
  ACCEPTED = 'accepted',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  INITIAL = 'initial',
  INITIAL_MEMBERSHIP = 'initial_membership',
  USER_ACCEPTED = 'user_accepted',
  USER_REFUSED = 'user_refused',
  PRESIDENT_ACCEPTED = 'president_accepted',
  PRESIDENT_REFUSED = 'president_refused',
  PENDING_SIGN = 'pending_sign',
  VOIDED = 'voided',
  DECLINED = 'declined',
  EXPIRED = 'expired',
  GUEST_ACCEPTED = 'guest_accepted',
  GUEST_REFUSED = 'guest_refused',
  ABORTED = 'aborted',
  SUBMITTED = 'submitted',
  SIGNED = 'signed',
}

export enum TypeOptionValuesEnum {
  president = 'president',
  member = 'member',
  foundingMember = 'foundingMember',
}
