import { Stack } from '@mui/system';
import { useAuth } from 'src/domains/system/auth/hooks/useAuth';
import { UserRole } from 'src/types/enums/user-roles-enum';

import { DisableClub } from './components/DisableClub';
import { ClubInfos, PracticalInfos, PresidentDetail } from './detail/siege';

export const ClubShowGeneral = () => {
  const { user } = useAuth();
  const { roles } = user || {};
  const isSuperAdmin = roles ? roles.includes(UserRole.SUPER_ADMIN) : false;

  return (
    <>
      <Stack
        flexDirection={'row'}
        gap={'24px'}
      >
        <PresidentDetail />
        <ClubInfos />
      </Stack>
      <Stack sx={{ mt: 2 }}>
        {isSuperAdmin && (
          <Stack
            flexDirection={'row'}
            gap={'24px'}
          >
            <PracticalInfos />
            <DisableClub />
          </Stack>
        )}
        {!isSuperAdmin && <PracticalInfos />}
      </Stack>
    </>
  );
};
