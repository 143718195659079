export enum ClubInvitationsSourceEnum {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE = 'phone',
  COMPANY = 'organizationName',
  STATUS = 'status',
  INVITED_BY = 'sender.fullName',
  DATE_DISCOVERY_MEETING = 'eventDate',
}
