export const paths = {
  index: '/',
  club: {
    clubs: {
      list: '/club/clubs',
    },
    members: {
      list: '/club/members',
    },
    leads: {
      list: '/club/leads',
    },
    registrations: {
      list: '/club/club_registrations',
    },
    invitations: {
      list: '/club/requests/invitations',
    },
    users: {
      list: '/club/users',
    },
    helpdesk: {
      list: '/club/helpdesk/tickets',
    },
    contract: {
      create: '/club/contracts/create',
    },
  },
  admin: {
    users: {
      list: '/admin_users',
    },
    agencies: {
      list: '/agencies',
    },
  },
  others: {
    settings: {
      list: '/my-settings/personal-infos',
      personal: {
        personalInfos: {
          list: '/my-settings/personal-infos',
        },
      },
    },
    logout: {
      list: '/logout',
    },
  },
  auth: {
    login: '/auth/login',
    two_factor: '/auth/2fa',
    forgot_password: '/auth/forgot-password/request',
    reset_password: '/auth/forgot-password/reset',
  },
  notAuthorized: '/401',
  notFound: '/404',
  serverError: '/500',
} as const;
