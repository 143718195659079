import React, { useEffect } from 'react';
import { SearchFilter } from 'src/common/components/filters';
import { TotalItems } from 'src/common/components/TotalItems';
import { FilterLayout } from 'src/common/layouts/react-admin/FilterLayout';
import { useListExportModalContext } from 'src/common/modals/hooks/useListExportModalContext';
import { ClubUserSearchFilterEnum } from 'src/data/enum/local/searchFilterEnum';
import { useReactAdminFilters } from 'src/hooks/useReactAdminFilters';

export const ClubUserFilters: React.FC = () => {
  const { filterValues, setFilters } = useReactAdminFilters();
  const { setGetFilters } = useListExportModalContext();

  useEffect(() => {
    setGetFilters(() => () => filterValues);
  }, [filterValues, setGetFilters]);

  return (
    <FilterLayout>
      <SearchFilter
        filterName={ClubUserSearchFilterEnum.SEARCH_TERM}
        filterValues={filterValues}
      />
      <TotalItems />
    </FilterLayout>
  );
};
