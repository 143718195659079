import { Grid, Skeleton } from '@mui/material';

export const StatsBundleInvitationsSkeleton = () => {
  return (
    <>
      <Grid
        container
        spacing={1}
        width={'100%'}
        display={'flex'}
        justifyContent={'space-around'}
        flexDirection={{ sm: 'column', md: 'row' }}
        alignItems={'center'}
        sx={{
          width: '100%',
        }}
      >
        <Grid
          item
          md={2}
          sx={{ paddingLeft: '0px', marginLeft: '-10px' }}
        >
          <Skeleton
            variant="rounded"
            height={150}
            width={300}
          />
        </Grid>
        <Grid
          item
          md={2}
        >
          <Skeleton
            variant="rounded"
            height={150}
            width={300}
          />
        </Grid>
        <Grid
          item
          md={2}
        >
          <Skeleton
            variant="rounded"
            height={100}
          />
        </Grid>
      </Grid>
    </>
  );
};
