import { Box, Card, Stack, Typography } from '@mui/material';
import { useEffect, useRef } from 'react';
import { indigo } from 'src/theme/colors';
import { animateValue } from 'src/utils/animateValue';
interface CardStatProps {
  statIconSrc: string;
  statName: string;
  statNumber: number;
  statSubNumber?: string;
}

export const CardStat = ({ statIconSrc, statName, statNumber, statSubNumber }: CardStatProps) => {
  const statNumberRef = useRef<HTMLParagraphElement>(null);
  useEffect(() => {
    const statNumberElement = statNumberRef.current;
    animateValue({ obj: statNumberElement, start: 0, end: statNumber, duration: 500 });
  }, [statNumber, statSubNumber]);
  return (
    <Card
      sx={{
        backgroundColor: indigo.main,
        minHeight: { sm: '80px', md: '100px' },
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Stack
        direction={'row'}
        sx={{
          padding: '10px',
          display: 'flex',
          justifyContent: { sm: 'space-evenly', md: 'space-around' },
          width: '100%',
          flexDirection: { sm: 'row', md: 'column', lg: 'row' },
          height: { md: '200px', lg: 'auto' },
          minWidth: { sm: '100%', md: 'auto', lg: 'auto' },
        }}
      >
        <Box
          alt="stats"
          component="img"
          src={statIconSrc}
          sx={{
            height: '50%',
            maxHeight: '60px',
            width: 'auto',
            display: 'flex',
            alignSelf: 'center',
            padding: { md: '10px', lg: '5px' },
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: { sm: '300px', md: 'auto' },
          }}
        >
          <Typography
            color={'white'}
            sx={{ textAlign: 'center', fontSize: '14px', paddingRight: '5px' }}
          >
            {statName}
          </Typography>
          <Box>
            <Typography
              color={'white'}
              sx={{
                fontSize: '24px',
                fontWeight: 'bold',
                display: 'inline',
              }}
              ref={statNumberRef}
            >
              {statNumber}
            </Typography>
          </Box>
          {statSubNumber && (
            <Typography
              color={'white'}
              sx={{
                fontSize: '14px',
                display: 'inline',
                paddingLeft: '5px',
              }}
            >
              {statSubNumber}
            </Typography>
          )}
        </Box>
      </Stack>
    </Card>
  );
};
