import { Grid } from '@mui/material';
import { CardStat } from 'src/domains/club/clubs/components/CardStat';

interface StatsBundleProps {
  data: Data;
  stats: Stats[];
  haveSubStats?: boolean;
}

interface Data {
  registration_pending_sign: number;
  registration_accepted_by_user: number;
  registration_refused_by_user: number;
  registration_refused_by_president: number;
  registration_expired: number;
}

type Stats = {
  statIconSrc: string;
  statName: string;
  statStatus: string;
  showSubStats?: boolean;
};

export const StatsBundle = ({ data, stats }: StatsBundleProps) => {
  return (
    <Grid
      container
      spacing={1}
      width={'100%'}
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: { sm: 'column', md: 'row' },
      }}
    >
      <Grid
        item
        md={2}
      >
        <CardStat
          statIconSrc={stats[0].statIconSrc}
          statName={stats[0].statName}
          statNumber={data?.registration_pending_sign}
        />
      </Grid>
      <Grid
        item
        md={2}
      >
        <CardStat
          statIconSrc={stats[1].statIconSrc}
          statName={stats[1].statName}
          statNumber={data?.registration_accepted_by_user}
        />
      </Grid>
      <Grid
        item
        md={2}
      >
        <CardStat
          statIconSrc={stats[2].statIconSrc}
          statName={stats[2].statName}
          statNumber={data?.registration_refused_by_user}
        />
      </Grid>
      <Grid
        item
        md={2}
      >
        <CardStat
          statIconSrc={stats[3].statIconSrc}
          statName={stats[3].statName}
          statNumber={data?.registration_refused_by_president}
        />
      </Grid>
      <Grid
        item
        md={2}
      >
        <CardStat
          statIconSrc={stats[4].statIconSrc}
          statName={stats[4].statName}
          statNumber={data?.registration_expired}
        />
      </Grid>
    </Grid>
  );
};
