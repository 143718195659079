import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { DateField, FunctionField, TextField, NumberField, useResourceContext } from 'react-admin';
import { ColumnHeaderLabel } from 'src/common/enums/react-admin';
import { DatagridLayout } from 'src/common/layouts/react-admin';
import { ClubSortByEnum, MemberSortByEnum } from 'src/data/enum/local/orderFilterEnum';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import { StatusField } from 'src/domains/club/clubs/components/react-admin/fields';
import { tokens } from 'src/locales/tokens';

import { ClubLeadsSourceEnum } from '../../enums/clubLeads-source-enum';

interface CenteredCellProps {
  children: React.ReactNode;
}

interface Lead {
  '@id': string;
  '@type': string;
  clubName: string;
  createdAt: string;
  fullName: string;
  id: string;
  originId: string;
  recipient: LeadUser;
  sender: LeadUser;
  status: string;
  updatedAt: string;
}

interface LeadUser {
  '@type': string;
  '@id': string;
  email: string;
  firstName: string;
  fullName: string;
  id: string;
  jobTitle: string;
  lastName: string;
  organization: LeadOrganization;
  phone: string;
}

interface LeadOrganization {
  '@type': string;
  '@id': string;
  email: string;
  organization: {
    '@type': string;
    '@id': string;
    name: string;
    brand: string;
    businessIdentifier: string;
    companyRegister: string;
    legalForm: {
      '@id': string;
      '@type': string;
      name: string;
      description: string;
      code: string;
      id: string;
    };
    activity: string;
    size: number;
    capital: string;
    website: string;
    createdAt: string;
    updatedAt: string;
    id: string;
    mediaSizes: string[];
  };
}

const CenteredCell: React.FC<CenteredCellProps> = ({ children }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    {children}
  </div>
);

export const ClubLeadsGrid = () => {
  const resource = useResourceContext();

  return (
    <DatagridLayout content={t(tokens.domains.leads.list.grid.noResult)}>
      <FunctionField
        label={ColumnHeaderLabel.senderName}
        source={ClubLeadsSourceEnum.SENDER}
        render={(record: Lead) => {
          return (
            <Stack>
              <Typography sx={{ fontSize: '0.875rem', textDecoration: 'underline' }}>
                {record.sender.fullName}
              </Typography>
              <Typography sx={{ fontSize: '0.875rem', color: 'grey' }}>
                {record.sender.email}
              </Typography>
              <Typography sx={{ fontSize: '0.875rem', color: 'grey' }}>
                {record.sender.phone}
              </Typography>
            </Stack>
          );
        }}
      />
      <FunctionField
        label={ColumnHeaderLabel.recipientName}
        source={ClubLeadsSourceEnum.RECIPIENT}
        render={(record: Lead) => {
          return (
            <Stack>
              <Typography sx={{ fontSize: '0.875rem', textDecoration: 'underline' }}>
                {record.recipient.fullName}
              </Typography>
              <Typography sx={{ fontSize: '0.875rem', color: 'grey' }}>
                {record.recipient.email}
              </Typography>
              <Typography sx={{ fontSize: '0.875rem', color: 'grey' }}>
                {record.recipient.phone}
              </Typography>
            </Stack>
          );
        }}
      />
      <FunctionField
        label={ColumnHeaderLabel.status}
        sortBy={ClubSortByEnum.ORDER_BY_STATUS}
        render={() => (
          <CenteredCell>
            <StatusField
              source={ClubLeadsSourceEnum.STATUS}
              list={'leads'}
            />
          </CenteredCell>
        )}
      />
      <TextField
        source={ClubLeadsSourceEnum.LEAD_NAME}
        label={ColumnHeaderLabel.leadName}
        textAlign="center"
      />
      <TextField
        source={ClubLeadsSourceEnum.CLUB_NAME}
        label={ColumnHeaderLabel.clubName}
        textAlign="center"
      />
      <NumberField
        source={ClubLeadsSourceEnum.AMOUNT}
        label={ColumnHeaderLabel.amount}
        textAlign="center"
        options={{
          style: 'currency',
          currency: 'EUR',
        }}
      />
      <DateField
        source={ClubLeadsSourceEnum.CREATED_AT}
        label={ColumnHeaderLabel.createdAt}
        textAlign="center"
      />
    </DatagridLayout>
  );
};
