import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import { styled } from '@mui/material/styles';
import { t } from 'i18next';
import * as React from 'react';
import { tokens } from 'src/locales/tokens';

const config = [
  {
    id: 1,
    label: t(tokens.domains.contract.stepper.stepOne),
  },
  {
    id: 2,
    label: t(tokens.domains.contract.stepper.stepTwo),
  },
  {
    id: 3,
    label: t(tokens.domains.contract.stepper.stepThree),
  },
];

interface VerticalLinearStepperProps {
  step: number;
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderWidth: 1,
    backgroundColor: theme.palette.primary.lightest,
  },
}));

export const VerticalLinearStepper: React.FC<VerticalLinearStepperProps> = ({ step }) => {
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Stepper
        activeStep={step - 1}
        orientation="vertical"
        connector={<QontoConnector />}
      >
        {config.map((item) => (
          <Step key={item.label}>
            <StepLabel
              sx={{
                '& .MuiStepLabel-label': {
                  color: 'neutral.500',
                },
                '& .MuiStepLabel-label.Mui-active': {
                  color: 'primary.main',
                },
                '& .MuiStepLabel-label.Mui-completed': {
                  color: 'primary.main',
                },
                '& .MuiSvgIcon-root': {
                  color: 'neutral.400',
                },
                '& .MuiSvgIcon-root.Mui-active': {
                  color: 'primary.main',
                },
                '& .MuiSvgIcon-root.Mui-completed': {
                  color: 'primary.main',
                },
              }}
            >
              {item.label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
