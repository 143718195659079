import { ListBase, useRecordContext } from 'react-admin';
import { Club } from 'src/admin-api-types';

import { ClubLeadsList } from './ClubLeadsList';
import { ClubLeadsListStats } from './components/ClubLeadsListStats';

export const ClubShowLeads = () => {
  const record = useRecordContext<Club>();
  const clubId = record?.id;
  const url = `${clubId?.substring(1)}/leads`;

  return (
    <ListBase resource={url}>
      <ClubLeadsListStats />
      <ClubLeadsList />
    </ListBase>
  );
};
