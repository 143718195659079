import { Typography, Stack } from '@mui/material';
import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

import { SearchFilterContent } from './SearchFilter';

export const ErrorFallback = () => (
  <div>
    <SearchFilterContent
      filterName=""
      filterValues={{}}
    />
    <Stack
      spacing={2}
      sx={{ mt: 1 }}
    >
      <Typography
        variant="caption"
        sx={{
          display: 'block',
          color: 'error.main',
        }}
      >
        {t(tokens.errors.generic)}
      </Typography>
    </Stack>
  </div>
);
