import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';

export const ColumnHeaderLabel = {
  leadName: t(tokens.headerColumnReactAdmin.leadName),
  clubName: t(tokens.headerColumnReactAdmin.clubName),
  president: t(tokens.headerColumnReactAdmin.president),
  agency: t(tokens.headerColumnReactAdmin.agency),
  format: t(tokens.headerColumnReactAdmin.format),
  department: t(tokens.headerColumnReactAdmin.department),
  status: t(tokens.headerColumnReactAdmin.status),
  members: t(tokens.headerColumnReactAdmin.members),
  createdAt: t(tokens.headerColumnReactAdmin.createdAt),
  modifyAt: t(tokens.headerColumnReactAdmin.modifyAt),
  action: t(tokens.headerColumnReactAdmin.action),
  memberName: t(tokens.headerColumnReactAdmin.memberName),
  organization: t(tokens.headerColumnReactAdmin.organization),
  type: t(tokens.headerColumnReactAdmin.type),
  payment: t(tokens.headerColumnReactAdmin.payment),
  signedAt: t(tokens.headerColumnReactAdmin.signedAt),
  disabledAt: t(tokens.headerColumnReactAdmin.disabledAt),
  isActive: t(tokens.headerColumnReactAdmin.isActive),
  role: t(tokens.headerColumnReactAdmin.role),
  invitedBy: t(tokens.headerColumnReactAdmin.invitedBy),
  dateDiscoveryMeeting: t(tokens.headerColumnReactAdmin.dateDiscoveryMeeting),
  guestName: t(tokens.headerColumnReactAdmin.guestName),
  amount: t(tokens.headerColumnReactAdmin.amount),
  senderName: t(tokens.headerColumnReactAdmin.senderName),
  recipientName: t(tokens.headerColumnReactAdmin.recipientName),
};
