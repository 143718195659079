import CloseIcon from '@mui/icons-material/Close';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { useState } from 'react';
import {
  EditBase,
  SimpleForm,
  SaveButton,
  useRecordContext,
  ValidateForm,
  useNotify,
  useRefresh,
} from 'react-admin';
import { Club } from 'src/admin-api-types';
import { useMutationOptions } from 'src/common/hooks';
import { TopLabelField } from 'src/components/react-admin/core/fields';
import ClubResourceEnum from 'src/data/enum/remote/club/club-resource-enum';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { DateInputLabel } from 'src/domains/club/members/components/DateInputLabel';
import { CustomInputLabel } from 'src/domains/club/members/detail/fields/CustomInputLabel';
import { tokens } from 'src/locales/tokens';

type DisableClubModalProps = {
  isOpen: boolean;
  handleClose: () => void;
  clubName: string | undefined;
};

export interface DisableClubFormData {
  disableDate: string;
  clubName: string;
  disabled: boolean;
}

export interface DisableClubModalPayload {
  disabledAt: string;
}

const transformDisableData = (values: DisableClubFormData): DisableClubModalPayload => {
  const date: Dayjs = dayjs(values.disableDate);
  const formattedDate: string = date.startOf('day').format('YYYY-MM-DDTHH:mm:ssZ');

  const payload: DisableClubModalPayload = {
    disabledAt: formattedDate,
  };

  return payload;
};

export const DisableClubModal = ({
  isOpen = false,
  handleClose,
  clubName,
}: DisableClubModalProps) => {
  const record = useRecordContext<Club>();
  const notify = useNotify();
  const refresh = useRefresh();
  const [isLoading, setIsLoading] = useState(false);

  const mutationOptions = useMutationOptions({
    vertical: 'top',
    horizontal: 'center',
    successLabel: t(tokens.domains.clubs.detail.notify.success),
    failLabel: t(tokens.domains.clubs.detail.notify.fail),
  });

  const validateForm: ValidateForm = (values) => {
    const errors: Record<string, string> = {};
    const typedValues = values as DisableClubFormData;

    if (clubName && typedValues.clubName !== clubName) {
      errors.clubName = t(tokens.modale.disableClub.error.noMatchClubName);
    }
    if (typedValues.disableDate === undefined) {
      errors.disableDate = t(tokens.modale.disableClub.error.noDisableDate);
    }
    return errors;
  };

  const handleSubmit = async (values: DisableClubFormData) => {
    try {
      setIsLoading(true);
      const transformedData = transformDisableData(values);
      await dataProvider.disableMember(record?.id as string, transformedData);
      notify(t(tokens.domains.clubs.detail.notify.success), { type: 'success' });
      refresh();
      handleClose();
    } catch (error) {
      if (error instanceof Error) {
        notify(error.message, { type: 'error' });
      } else {
        notify(t(tokens.domains.clubs.detail.notify.fail), { type: 'error' });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      sx={{
        '& .MuiDialog-paper': {
          width: '700px',
        },
      }}
    >
      <CloseIcon
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 5,
          top: 5,
          cursor: 'pointer',
          color: 'grey.700',
          borderRadius: '50%',
          margin: 1.5,
          padding: 0.5,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'left',
          margin: '0 48px 24px',
          mt: 4,
        }}
      >
        <DeleteOutlineIcon
          sx={{
            color: 'error.main',
            mr: 1,
          }}
        />
        <DialogTitle sx={{ pl: 0 }}>
          {t(tokens.domains.clubs.detail.disableClubCardTitle)}
        </DialogTitle>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F1F1FF',
          margin: '0 48px 24px',
          borderRadius: '10px',
          gap: 2,
          px: 3.5,
          py: 2,
        }}
      >
        <Typography sx={{ fontSize: '14px', color: 'grey.600' }}>
          {t(tokens.domains.clubs.detail.disableClubCardDescriptionModal, {
            NOM_DU_CLUB: clubName,
          })}
        </Typography>
      </Box>

      <EditBase
        mutationMode="pessimistic"
        redirect={false}
        resource={ClubResourceEnum.CLUBS}
        mutationOptions={mutationOptions}
        transform={transformDisableData}
        queryOptions={{ enabled: false }}
      >
        <SimpleForm
          onSubmit={handleSubmit}
          validate={validateForm}
          toolbar={false}
        >
          <Stack
            direction="column"
            spacing={4}
            sx={{ px: 3.5, pb: 2, width: '100%' }}
          >
            <TopLabelField label="Date de désactivation :">
              <DateInputLabel
                source="disableDate"
                placeholder="JJ/MM/AAAA"
              />
            </TopLabelField>
            <CustomInputLabel
              source={'clubName'}
              label={`${t(tokens.modale.disableClub.clubName)} : ${clubName}`}
              placeholder="Nom du club (Exemple : Dynabuy)"
            />
          </Stack>
          <DialogActions sx={{ width: '100%', mt: 2 }}>
            <Box
              sx={{
                px: 1,
                pb: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'end',
                gap: 1,
                marginRight: 1,
              }}
            >
              <Button
                onClick={handleClose}
                variant="outlined"
              >
                {t(tokens.domains.settings.personalInfos.detail.cancel)}
              </Button>
              <SaveButton
                label={isLoading ? '' : t(tokens.common.button.validate)}
                icon={isLoading ? <CircularProgress size={20} /> : <></>}
                disabled={isLoading}
                sx={{ minWidth: 100 }}
              />
            </Box>
          </DialogActions>
        </SimpleForm>
      </EditBase>
    </Dialog>
  );
};
