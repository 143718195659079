import { t } from 'i18next';
import React, { useEffect } from 'react';
import { DatePickerFilter, SearchFilter, SplitButtonFilter } from 'src/common/components/filters';
import ResetFiltersButton from 'src/common/components/ResetFiltersButton';
import { TagSummary } from 'src/common/components/TagSummary';
import { TotalItems } from 'src/common/components/TotalItems';
import { FilterLayout } from 'src/common/layouts/react-admin/FilterLayout';
import TaggedFilter from 'src/contexts/common/filters/tagged-filter-context/components/TaggedFilter';
import { ClubLeadsSearchFilterEnum } from 'src/data/enum/local/searchFilterEnum';
import {
  clubLeadsStatusOptions,
  Option,
} from 'src/domains/club/clubs/utils/clubLeadsStatusOptions';
import { useMembershipsFilters } from 'src/hooks/useCustomFilter';
import { useDatePickerRangeHandler } from 'src/hooks/useDatePickerRangeHandler';
import { tokens } from 'src/locales/tokens';
import { FilterLabels, FilterValues } from 'src/types/enums';
import { getDateYearsAgo } from 'src/utils/getDateYearsAgo';

export const LeadsFilters: React.FC = () => {
  const { filterValues, setFilters } = useMembershipsFilters();
  const { startDate, endDate, setStartDate, setEndDate } = useDatePickerRangeHandler();

  useEffect(() => {
    setFilters({}, {});
    setStartDate?.(undefined);
    setEndDate?.(undefined);
  }, []);

  return (
    <FilterLayout>
      <>
        <SearchFilter
          filterName={ClubLeadsSearchFilterEnum.SEARCH_TERM}
          filterValues={filterValues}
          placeholder={t(tokens.placeholder.leadsSearch)}
        />
        <ResetFiltersButton
          setFilters={setFilters}
          filterValues={filterValues}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      </>
      <>
        <TotalItems />
      </>
      <>
        <TaggedFilter
          component={SplitButtonFilter}
          label={FilterLabels.Status}
          value={FilterValues.STATUS}
          options={clubLeadsStatusOptions as Option[]}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={DatePickerFilter}
          label={FilterLabels.CreatedAt}
          value={FilterValues.CREATED_AFTER}
          minDate={getDateYearsAgo(15)}
          maxDate={endDate}
          setStartDate={setStartDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
        <TaggedFilter
          component={DatePickerFilter}
          minDate={startDate}
          label={FilterLabels.EndAt}
          value={FilterValues.CREATED_BEFORE}
          setEndDate={setEndDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
      </>
      <>
        <TagSummary
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          filterValues={filterValues}
          setFilters={setFilters}
        />
      </>
    </FilterLayout>
  );
};
