import { Box } from '@mui/system';
import React from 'react';
import { Pagination } from 'react-admin';
import { ListBaseLayout } from 'src/common/layouts/react-admin/ListBaseLayout';

import { ClubInvitationsFilters } from '../../clubs/components/filters/ClubInvitationsFilters';
import { ClubInvitationsGrid } from '../../clubs/components/grid/ClubInvitationsGrid';

import { InvitationsStats } from './InvitationStats';

export const InvitationListTable: React.FC = () => {
  return (
    <ListBaseLayout>
      <Box sx={{ marginBottom: 4, marginLeft: 1 }}>
        <InvitationsStats />
      </Box>
      <ClubInvitationsFilters />
      <ClubInvitationsGrid />
      <Pagination />
    </ListBaseLayout>
  );
};
