import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { StatsBundleInvitations } from 'src/common/components/StatsBundleInvitations';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { tokens } from 'src/locales/tokens';

import { StatsBundleInvitationsSkeleton } from '../../clubs/skeletons/StatsBundleInvitationsSkeleton';

interface Data {
  invitation_accepted_by_president: number;
  invitation_refused_by_president: number;
  invitation_accepted_by_guest: number;
  invitation_refused_by_guest: number;
  participation_accepted_by_president: number;
  participation_refused_by_president: number;
  request_total: number;
}

interface Club {
  id: string;
}

export const ClubInvitationsListStats = () => {
  const record = useRecordContext<Club>();
  const clubUrl = record?.id;
  const clubId = clubUrl ? clubUrl.substring(12) : '';

  const [data, setData] = useState<Data>({
    request_total: 0,
    invitation_accepted_by_president: 0,
    invitation_refused_by_president: 0,
    invitation_accepted_by_guest: 0,
    invitation_refused_by_guest: 0,
    participation_accepted_by_president: 0,
    participation_refused_by_president: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stats = await dataProvider.getStats(`club/stats/`, {
          code: [
            'request_total',
            'invitation_accepted_by_president',
            'invitation_refused_by_president',
            'invitation_accepted_by_guest',
            'invitation_refused_by_guest',
            'participation_accepted_by_president',
            'participation_refused_by_president',
          ],
          clubId,
        });

        const statsMap = stats['hydra:member'].reduce(
          (acc, stat) => ({
            ...acc,
            [stat.code]: stat.value,
          }),
          {}
        );

        setData({
          request_total: statsMap.request_total || 0,
          invitation_accepted_by_president: statsMap.invitation_accepted_by_president || 0,
          invitation_refused_by_president: statsMap.invitation_refused_by_president || 0,
          invitation_accepted_by_guest: statsMap.invitation_accepted_by_guest || 0,
          invitation_refused_by_guest: statsMap.invitation_refused_by_guest || 0,
          participation_accepted_by_president: statsMap.participation_accepted_by_president || 0,
          participation_refused_by_president: statsMap.participation_refused_by_president || 0,
        });
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Une erreur est survenue'));
      } finally {
        setIsLoading(false);
      }
    };
    void fetchData();
  }, [clubId]);

  const titlesSplitCard = {
    title: t(tokens.domains.clubs.detail.invitationsStats.sent),
    subtitleSection1: t(tokens.filters.role.president),
    subtitleSection2: t(tokens.filters.role.guest),
  };

  const titlesDoubleCard = {
    title: t(tokens.domains.clubs.detail.invitationsStats.participation),
    subtitle: t(tokens.filters.role.president),
  };

  const stats = [
    {
      statIconSrc: '/assets/icons/icon-email.svg',
      statName: t(tokens.filters.status.active),
      statStatus: 'president_accepted',
    },
    {
      statIconSrc: '/assets/icons/icon-no.svg',
      statName: t(tokens.filters.status.inactive),
      statStatus: 'president_refused',
    },
    {
      statIconSrc: '/assets/icons/icon-validation.svg',
      statName: t(tokens.filters.status.active),
      statStatus: 'guest_accepted',
    },
    {
      statIconSrc: '/assets/icons/icon-no-entry.svg',
      statName: t(tokens.filters.status.inactive),
      statStatus: 'guest_refused',
    },
    {
      statIconSrc: '/assets/icons/icon-raise-hand.svg',
      statName: t(tokens.domains.clubs.detail.invitationsStats.total),
      statStatus: 'total',
    },
  ];

  if (isLoading) {
    return <StatsBundleInvitationsSkeleton />;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <StatsBundleInvitations
      statsNumber={data ?? []}
      statsInfos={stats}
      titlesSplitCard={titlesSplitCard}
      titlesDoubleCard={titlesDoubleCard}
    />
  );
};
