import { Box, Stack } from '@mui/material';
import { t } from 'i18next';
import { Pagination } from 'react-admin';
import { Seo } from 'src/components/Seo';
import { tokens } from 'src/locales/tokens';

import { ClubLeadsFilters } from './components/filters/ClubLeadsFilters';
import { ClubLeadsGrid } from './components/grid/ClubLeadsGrid';

export const ClubLeadsList = () => {
  return (
    <>
      <Seo title={t(tokens.seo.club.title)} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Stack spacing={1}>
          <Stack sx={{ marginTop: '-60px' }}>
            <ClubLeadsFilters />
            <ClubLeadsGrid />
            <Pagination />
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
