import { ListBase, useRecordContext } from 'react-admin';
import { Club } from 'src/admin-api-types';

import { ClubInvitationsList } from './ClubInvitationsList';
import { ClubInvitationsListStats } from './components/ClubInvitationsListStats';

export const ClubShowInvitations = () => {
  const record = useRecordContext<Club>();
  const clubId = record?.id;
  const url = `${clubId?.substring(1)}/requests/invitations`;

  return (
    <ListBase resource={url}>
      <ClubInvitationsListStats />
      <ClubInvitationsList />
    </ListBase>
  );
};
