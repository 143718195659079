import { Grid } from '@mui/material';
import { CardStat } from 'src/domains/club/clubs/components/CardStat';

import { CardStatsDouble } from './CardStatsDouble';
import { CardStatsSplit } from './CardStatsSplit';

interface StatsBundleProps {
  statsNumber: StatsNumber;
  statsInfos: StatsInfos[];
  titlesSplitCard: TitlesSplitCard;
  titlesDoubleCard: TitlesDoubleCard;
}

type StatsNumber = {
  invitation_accepted_by_guest: number;
  invitation_accepted_by_president: number;
  invitation_refused_by_guest: number;
  invitation_refused_by_president: number;
  participation_accepted_by_president: number;
  participation_refused_by_president: number;
  request_total: number;
};

type StatsInfos = {
  statIconSrc: string;
  statName: string;
  statStatus: string;
};

type TitlesSplitCard = {
  title: string;
  subtitleSection1: string;
  subtitleSection2: string;
};

type TitlesDoubleCard = {
  title: string;
  subtitle?: string;
};

export const StatsBundleInvitations = ({
  statsNumber,
  statsInfos,
  titlesSplitCard,
  titlesDoubleCard,
}: StatsBundleProps) => {
  return (
    <Grid
      container
      spacing={1}
      width={'100%'}
      display={'flex'}
      justifyContent={'space-around'}
      flexDirection={{ sm: 'column', md: 'row' }}
      alignItems={'center'}
      sx={{
        width: '100%',
      }}
    >
      <Grid item>
        <CardStatsSplit
          title={titlesSplitCard.title}
          subtitleSection1={titlesSplitCard.subtitleSection1}
          section1Stat1={{
            iconSrc: statsInfos[0].statIconSrc,
            name: statsInfos[0].statName,
            number: statsNumber.invitation_accepted_by_president,
          }}
          section1Stat2={{
            iconSrc: statsInfos[1].statIconSrc,
            name: statsInfos[1].statName,
            number: statsNumber.invitation_refused_by_president,
          }}
          subtitleSection2={titlesSplitCard.subtitleSection2}
          section2Stat1={{
            iconSrc: statsInfos[2].statIconSrc,
            name: statsInfos[2].statName,
            number: statsNumber.invitation_accepted_by_guest,
          }}
          section2Stat2={{
            iconSrc: statsInfos[3].statIconSrc,
            name: statsInfos[3].statName,
            number: statsNumber.invitation_refused_by_guest,
          }}
        />
      </Grid>
      <Grid item>
        <CardStatsDouble
          title={titlesDoubleCard.title}
          subtitle={titlesDoubleCard.subtitle}
          stat1={{
            iconSrc: statsInfos[0].statIconSrc,
            name: statsInfos[0].statName,
            number: statsNumber.participation_accepted_by_president,
          }}
          stat2={{
            iconSrc: statsInfos[1].statIconSrc,
            name: statsInfos[1].statName,
            number: statsNumber.participation_refused_by_president,
          }}
        />
      </Grid>
      <Grid item>
        <CardStat
          statIconSrc={statsInfos[4].statIconSrc}
          statName={statsInfos[4].statName}
          statNumber={statsNumber.request_total}
        />
      </Grid>
    </Grid>
  );
};
