import { RaRecord } from 'ra-core';
import React, { useEffect, useState } from 'react';
import { useGetList } from 'react-admin';
import { SplitButtonFilter } from 'src/common/components/filters/SplitButtonFilter/SplitButtonFilter';
import { AsSplitButtonFilterRenderOption } from 'src/common/components/filters/SplitButtonFilter/SplitButtonFilterRenderOption';

export interface ReferenceSplitButtonFilterProps {
  label: string;
  resource?: string;
  optionLabel?: string;
  optionValue?: string;
  value: string;
  onOptionChanged?: (options: []) => void;
  renderOption?: React.FC<AsSplitButtonFilterRenderOption>;
  options: [];
  setFilters: (
    filters: { [key: string]: string[] | Date },
    displayedFilters: unknown,
    debounce?: boolean
  ) => void;
  filterValues: { [key: string]: string[] | Date };
}

const ReferenceSplitButtonFilter: React.FC<ReferenceSplitButtonFilterProps> = ({
  resource,
  optionLabel,
  optionValue = 'originId',
  value,
  options,
  onOptionChanged,
  ...rest
}) => {
  const { data, isLoading } = useGetList(resource || '', { pagination: { page: 1, perPage: 100 } });
  const [stateOptions, setStateOptions] = useState<FilterOption[]>([]);
  useEffect(() => {
    if (!isLoading) {
      if (data !== undefined) {
        const preparedOptions = data.map((item: RaRecord) => {
          return {
            item,
            label: optionLabel !== undefined ? (item[optionLabel] as string) : item,
            value: item[optionValue] as string,
          } as FilterOption;
        });
        if (!onOptionChanged) {
          setStateOptions(preparedOptions);
        } else {
          onOptionChanged(preparedOptions as []);
        }
      }
    }
  }, [optionLabel, optionValue, isLoading, data, onOptionChanged]);

  return (
    <SplitButtonFilter
      options={options || stateOptions}
      isLoading={isLoading}
      value={value}
      {...rest}
    />
  );
};

export default ReferenceSplitButtonFilter;
