import { t } from 'i18next';
import { useState, useEffect } from 'react';
import { useRecordContext } from 'react-admin';
import { Club } from 'src/admin-api-types';
import { StatsBundle } from 'src/common/components/StatsBundle';
import dataProvider from 'src/data/provider/remote/dataProvider';
import { tokens } from 'src/locales/tokens';

import { StatsBundleSkeleton } from '../../clubs/skeletons/StatsBundleSkeleton';

interface Data {
  registration_pending_sign: number;
  registration_accepted_by_user: number;
  registration_refused_by_user: number;
  registration_refused_by_president: number;
  registration_expired: number;
}

interface Club {
  id: string;
}

export const ClubMembershipListStats = () => {
  const record = useRecordContext<Club>();
  const clubUrl = record?.id;
  const clubId = clubUrl ? clubUrl.substring(12) : '';

  const [data, setData] = useState<Data>({
    registration_pending_sign: 0,
    registration_accepted_by_user: 0,
    registration_refused_by_user: 0,
    registration_refused_by_president: 0,
    registration_expired: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const stats = await dataProvider.getStats('club/stats', {
          code: [
            'registration_pending_sign',
            'registration_accepted_by_user',
            'registration_refused_by_user',
            'registration_refused_by_president',
            'registration_expired',
          ],
          clubId: clubId ?? '',
        });

        const statsMap = stats['hydra:member'].reduce(
          (acc, stat) => ({
            ...acc,
            [stat.code]: stat.value,
          }),
          {}
        );

        setData({
          registration_pending_sign: statsMap.registration_pending_sign || 0,
          registration_accepted_by_user: statsMap.registration_accepted_by_user || 0,
          registration_refused_by_user: statsMap.registration_refused_by_user || 0,
          registration_refused_by_president: statsMap.registration_refused_by_president || 0,
          registration_expired: statsMap.registration_expired || 0,
        });
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Une erreur est survenue'));
      } finally {
        setIsLoading(false);
      }
    };
    void fetchData();
  }, [clubId]);

  const stats = [
    {
      statIconSrc: '/assets/icons/icon-sign.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.pendingSignature),
      statStatus: 'pending_sign',
    },
    {
      statIconSrc: '/assets/icons/icon-wait.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.pendingValidation),
      statStatus: 'user_accepted',
    },
    {
      statIconSrc: '/assets/icons/icon-no.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.prospectDeclined),
      statStatus: 'user_refused',
    },
    {
      statIconSrc: '/assets/icons/icon-rejected.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.presidentDeclined),
      statStatus: 'president_refused',
    },
    {
      statIconSrc: '/assets/icons/icon-expired.svg',
      statName: t(tokens.domains.clubs.detail.membershipsStats.expired),
      statStatus: 'expired',
    },
  ];

  if (isLoading) {
    return <StatsBundleSkeleton />;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <StatsBundle
      data={data ?? []}
      stats={stats}
    />
  );
};
