import { Box, Button, Card, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { useState } from 'react';
import { useRecordContext } from 'react-admin';
import { Club } from 'src/admin-api-types';
import { DisableClubModal } from 'src/domains/club/clubs/components/DisableClubModal';
import { tokens } from 'src/locales/tokens';

import { CardTitle } from '../../members/detail/common/CardTitle';

export const DisableClub: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const record = useRecordContext<Club>();

  const handleClose = () => {
    setIsOpen(false);
  };

  const isDisabled = Boolean(record?.disabledAt);
  const disabledDate = record?.disabledAt ? dayjs(record.disabledAt).format('DD/MM/YYYY') : null;

  return (
    <Card
      sx={{
        width: '700px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardTitle text={t(tokens.domains.clubs.detail.disableClubCardTitle)} />
      <Stack
        flexGrow="1"
        padding={'0px 24px 30px 24px'}
        spacing={4}
      >
        <Box>
          <Button
            variant="contained"
            color="error"
            disabled={isDisabled}
            onClick={() => setIsOpen(true)}
            sx={{
              fontWeight: 'bold',
              height: '40px',
              borderRadius: '10px',
              borderWidth: '2px',
              textTransform: 'none',
              '&.Mui-disabled': {
                borderColor: 'grey.300',
                color: 'grey.500',
              },
              '&:hover': {
                borderWidth: '2px',
                backgroundColor: 'error.50',
              },
            }}
          >
            {t(tokens.domains.clubs.chips.disable)}
          </Button>
        </Box>
        <Typography sx={{ fontSize: '14px', color: 'grey.600', fontStyle: 'italic' }}>
          {isDisabled
            ? t(tokens.modale.disableClub.disableClubScheduledDescription, {
                DATE: disabledDate,
              })
            : t(tokens.domains.clubs.detail.disableClubCardDescription)}
        </Typography>
      </Stack>
      <DisableClubModal
        isOpen={isOpen}
        handleClose={handleClose}
        clubName={record?.name as string}
      />
    </Card>
  );
};
