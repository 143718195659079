import { Grid } from '@mui/material';
import { CardStat } from 'src/domains/club/clubs/components/CardStat';

interface StatsBundleLeadsProps {
  data: Data;
  stats: Stats[];
  haveSubStats?: boolean;
}

interface Data {
  lead_total: number;
  lead_total_amount: number;
  lead_sent: number;
  lead_received: number;
  lead_accepted: number;
  lead_refused: number;
  lead_transformed: number;
  lead_cancelled: number;
  lead_not_transformed: number;
  lead_expired: number;
}

type Stats = {
  statIconSrc: string;
  statName: string;
  statStatus: string;
  showSubStats?: boolean;
};

export const StatsBundleLeads = ({ data, stats }: StatsBundleLeadsProps) => {
  const leadAcceptedPercentage =
    data?.lead_sent === 0
      ? 0
      : (data?.lead_accepted /
          (data?.lead_accepted +
            data?.lead_refused +
            data?.lead_cancelled +
            data?.lead_received +
            data?.lead_sent)) *
        100;
  const leadTransformedPercentage =
    data?.lead_total === 0 ? 0 : (data?.lead_transformed / data?.lead_total) * 100;

  return (
    <Grid
      container
      spacing={1}
      width={'100%'}
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: { sm: 'column', md: 'row' },
      }}
    >
      <Grid
        item
        md={2}
        key={stats[0].statName}
      >
        <CardStat
          statIconSrc={stats[0].statIconSrc}
          statName={stats[0].statName}
          statNumber={data?.lead_sent}
        />
      </Grid>
      <Grid
        item
        md={2}
        key={stats[1].statName}
      >
        <CardStat
          statIconSrc={stats[1].statIconSrc}
          statName={stats[1].statName}
          statNumber={data?.lead_accepted}
          statSubNumber={`${leadAcceptedPercentage.toFixed(2)}%`}
        />
      </Grid>
      <Grid
        item
        md={2}
        key={stats[2].statName}
      >
        <CardStat
          statIconSrc={stats[2].statIconSrc}
          statName={stats[2].statName}
          statNumber={data?.lead_transformed}
          statSubNumber={`${leadTransformedPercentage.toFixed(2)}%`}
        />
      </Grid>
      <Grid
        item
        md={2}
        key={stats[3].statName}
      >
        <CardStat
          statIconSrc={stats[3].statIconSrc}
          statName={stats[3].statName}
          statNumber={data?.lead_total}
          statSubNumber={`${data?.lead_total_amount.toFixed(2)}€`}
        />
      </Grid>
    </Grid>
  );
};
