import { t } from 'i18next';
import { tokens } from 'src/locales/tokens';
import { pink, success, yellow, error, info } from 'src/theme/colors';
import { StatusOptionValuesEnum } from 'src/types/enums';

export type Option = {
  label: string;
  value: string;
};

const statusOptionsTrad = {
  refused: t(tokens.filters.status.refused),
  transformed: t(tokens.filters.status.transformed),
  sent: t(tokens.filters.status.sent),
  cancelled: t(tokens.filters.status.cancelled),
  accepted: t(tokens.filters.status.accepted),
  notTransformed: t(tokens.filters.status.notTransformed),
} as const;

const statusOptionsValues = {
  refused: StatusOptionValuesEnum.REFUSED,
  transformed: StatusOptionValuesEnum.TRANSFORMED,
  sent: StatusOptionValuesEnum.SENT,
  cancelled: StatusOptionValuesEnum.CANCELLED,
  accepted: StatusOptionValuesEnum.ACCEPTED,
  notTransformed: StatusOptionValuesEnum.NOT_TRANSFORMED,
} as const;

export const clubLeadsStatusOptions = [
  {
    label: statusOptionsTrad.refused,
    value: statusOptionsValues.refused,
    tagColor: {
      background: pink.main,
      text: pink.darkest,
    },
  },
  {
    label: statusOptionsTrad.transformed,
    value: statusOptionsValues.transformed,
    tagColor: {
      background: success.alpha30,
      text: success.darkest,
    },
  },
  {
    label: statusOptionsTrad.sent,
    value: statusOptionsValues.sent,
    tagColor: {
      background: yellow.alpha50,
      text: yellow.darkest,
    },
  },
  {
    label: statusOptionsTrad.cancelled,
    value: statusOptionsValues.cancelled,
    tagColor: {
      background: error.main,
      text: error.contrastText,
    },
  },
  {
    label: statusOptionsTrad.accepted,
    value: statusOptionsValues.accepted,
    tagColor: {
      background: success.main,
      text: success.contrastText,
    },
  },
  {
    label: statusOptionsTrad.notTransformed,
    value: statusOptionsValues.notTransformed,
    tagColor: {
      background: info.alpha50,
      text: info.darkest,
    },
  },
];
