import Typography from '@mui/material/Typography';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import { DateField, FunctionField, TextField } from 'react-admin';
import { ColumnHeaderLabel } from 'src/common/enums/react-admin';
import { DatagridLayout } from 'src/common/layouts/react-admin';
import { ClubSortByEnum, MemberSortByEnum } from 'src/data/enum/local/orderFilterEnum';
import { StatusField } from 'src/domains/club/clubs/components/react-admin/fields';
import { tokens } from 'src/locales/tokens';

import { ClubInvitationsSourceEnum } from '../../enums/clubInvitations-source-enum';

interface CenteredCellProps {
  children: React.ReactNode;
}

// TODO: Will be replaced by admin-api-types when it will be available
type Invitation = {
  anonymized: boolean;
  createdAt: string;
  email: string;
  eventDate: string;
  fullName: string;
  guestHasAccount: boolean;
  id: string;
  organizationName: string;
  originId: string;
  phone: string;
  sender: {
    '@id': string;
    '@type': string;
    email: string;
    fullName: string;
    id: string;
    jobTitle: string;
    phone: string;
  };
  status: string;
  updatedAt: string;
};

const CenteredCell: React.FC<CenteredCellProps> = ({ children }) => (
  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
    {children}
  </div>
);

export const ClubInvitationsGrid = () => {
  return (
    <DatagridLayout content={t(tokens.domains.invitations.list.grid.noResult)}>
      <FunctionField
        source={ClubInvitationsSourceEnum.FIRST_NAME}
        label={ColumnHeaderLabel.guestName}
        render={(record: Invitation) => {
          return (
            <>
              {!record.anonymized ? (
                <Stack>
                  <Typography sx={{ fontSize: '0.875rem' }}>{record.fullName}</Typography>
                  <TextField
                    source={ClubInvitationsSourceEnum.EMAIL}
                    variant={'caption'}
                  />
                  <TextField
                    source={ClubInvitationsSourceEnum.PHONE}
                    variant={'caption'}
                  />
                </Stack>
              ) : (
                <Typography sx={{ fontSize: '0.875rem' }}>
                  {t(tokens.domains.clubs.detail.anonymized)}
                </Typography>
              )}
            </>
          );
        }}
      />
      <TextField
        source={ClubInvitationsSourceEnum.COMPANY}
        label={ColumnHeaderLabel.organization}
        reference={ClubInvitationsSourceEnum.COMPANY}
      />
      <FunctionField
        label={ColumnHeaderLabel.status}
        sortBy={ClubSortByEnum.ORDER_BY_STATUS}
        render={() => (
          <CenteredCell>
            <StatusField
              source={ClubInvitationsSourceEnum.STATUS}
              list={'invitation'}
            />
          </CenteredCell>
        )}
      />
      <TextField
        source={ClubInvitationsSourceEnum.INVITED_BY}
        label={ColumnHeaderLabel.invitedBy}
        sortBy={MemberSortByEnum.ORGANISATION_DEPARTMENT}
      />
      <DateField
        source={ClubInvitationsSourceEnum.DATE_DISCOVERY_MEETING}
        label={ColumnHeaderLabel.dateDiscoveryMeeting}
        textAlign="center"
      />
    </DatagridLayout>
  );
};
