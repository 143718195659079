import Chip from '@mui/material/Chip';
import React, { useEffect, useState } from 'react';
import { useRecordContext } from 'react-admin';
import { clubMembershipStatusOptions } from 'src/domains/club/clubs/utils/clubMembershipStatusOptions';
import { clubStatusOptions } from 'src/domains/club/clubs/utils/clubOptions';

import { clubInvitationsStatusOptions } from '../../../utils/clubInvitationsStatusOptions';
import { clubLeadsStatusOptions } from '../../../utils/clubLeadsStatusOptions';
interface StatusFieldProps {
  source: string;
  list: 'club' | 'membership' | 'invitation' | 'leads';
}

interface StatusOption {
  label: string;
  value: string;
  tagColor: {
    background?: string;
    text: string;
  };
}

export const StatusField: React.FC<StatusFieldProps> = ({ list }) => {
  const record = useRecordContext();
  const [statusTotalOptions, setStatusTotalOptions] = useState<StatusOption[]>([]);

  useEffect(() => {
    if (list === 'club') {
      setStatusTotalOptions(clubStatusOptions);
    } else if (list === 'membership') {
      setStatusTotalOptions(clubMembershipStatusOptions);
    } else if (list === 'invitation') {
      setStatusTotalOptions(clubInvitationsStatusOptions);
    } else if (list === 'leads') {
      setStatusTotalOptions(clubLeadsStatusOptions);
    }
  }, [list]);

  const statusOption = statusTotalOptions.find((option) => option.value === record?.status);

  if (!statusOption) {
    return null;
  }

  const { tagColor } = statusOption;

  return (
    <Chip
      label={statusOption.label.toUpperCase()}
      style={{
        backgroundColor: tagColor?.background || 'defaultBackground',
        color: tagColor?.text || 'defaultText',
        height: '32px',
        fontWeight: 'bold',
        paddingLeft: '12px',
        paddingRight: '12px',
      }}
    />
  );
};
